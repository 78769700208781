
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_user_reports(params)  {
            return await axios.post('user_reports/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_user_reports()  {
            // return await axios.get('user_reports/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_user_reports')
        },
        async delete_user_reports(user_report_id)  {
            return await axios.post('user_reports/delete.php' , {
                user_report_id : user_report_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(user_report_id)  {
            return await axios.post('user_reports/readOne.php' , {
                user_report_id : user_report_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_user_reports(params)  {
            return await axios.post('user_reports/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_user_reports(params)  {
            return await axios.post('user_reports/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
